<template>
    <section style="position: relative">
        <div class="row">
            <div class="
          col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
          d-flex
          mb-3
        ">
                <div class="table-box p-3">
                    <div class="border-bottom pb-3">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h2 class="mb-1">
                                    {{
                                        getProjectData.project_name
                                        ? getProjectData.project_name
                                        : "N/A"
                                    }}
                                </h2>
                                <p class="">
                                    (
                                    {{ getProjectData.serial ? getProjectData.serial : "N/A" }} )
                                </p>
                            </div>
                            <div>
                                <button v-if="getProjectData.status != 'Completed'" class="btn btn-primary" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapse-Three" aria-expanded="true" aria-controls="collapse-Three" @click="completeProject(getProjectData)">
                                    {{ translations?.done_this_project }}
                                </button>
                            </div>
                        </div>

                        <div class="card mt-3" v-if="invitationData.invitation_status === 'Invited'">
                            <div class="card-body">
                                <h5 class="card-title mb-4">
                                    <font-awesome-icon :icon="['fas', `info-circle`]"></font-awesome-icon>
                                    <span class="ms-2">{{ invitationData.project_name }}</span>
                                </h5>
                                <p class="card-text mb-4">
                                    <font-awesome-icon :icon="['fas', `user-circle`]"></font-awesome-icon>
                                    <span class="ms-2">{{ invitationData.role }}</span>
                                </p>
                                <p class="card-text">
                                    <font-awesome-icon :icon="['fas', `calendar-day`]"></font-awesome-icon>
                                    <span class="ms-2">{{ invitationData.start_date }}</span>
                                </p>

                                <a href="#" class="btn btn-danger mt-3 me-4" @click.prevent="
                                    changeInvitationStatus(
                                        invitationData.id,
                                        invitationData.project_id,
                                        'Declined'
                                    )
                                    ">Decline</a>
                                <a href="#" class="btn btn-primary mt-3" @click.prevent="
                                    changeInvitationStatus(
                                        invitationData.id,
                                        invitationData.project_id,
                                        'Accepted'
                                    )
                                    ">Accept</a>
                            </div>
                        </div>
                    </div>
                    <div class="my-3">
                        <div class="row border-bottom mb-3">
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <div class="content-task-ctl">
                                    <h2 class="border-bottom mb-3"> Notifications </h2>
                                    <div class="table-responsive">
                                        <table class="table table-hover table-striped">
                                            <tbody>
                                                <tr v-if="notifications.length === 0">
                                                    <td colspan="4" class="text-center">No notifications found</td>
                                                </tr>

                                                <tr v-else v-for="notification in notifications" :key="notification?.id">
                                                    <td>
                                                    <a :href="notification?.url">{{ notification?.message }}</a>
                                                    </td>
                                                    <td>{{ formatDate(notification?.created_at) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="content-task-ctl">
                                    <h2 class="border-bottom mb-3"> {{ translations?.your_content_tasks }} </h2>
                                    <div class="table-responsive">
                                        <table class="table table-hover table-striped">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <a href=""> Rsvp and attend manage training</a>
                                                    </td>
                                                    <td>15 Oct</td>
                                                    <td>
                                                        <span class="btn btn-danger rounded-pill">High</span>
                                                    </td>
                                                    <td>
                                                        <div class="profile-avatar">
                                                            <img :src="require('@/assets/img/john.jpg')" alt=""
                                                                class="img-fluid" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><a href="">Assign a mentor for new hire</a></td>
                                                    <td>Today</td>
                                                    <td>
                                                        <span class="btn btn-warning rounded-pill">Medium</span>
                                                    </td>
                                                    <td>
                                                        <div class="profile-avatar">
                                                            <img :src="require('@/assets/img/john.jpg')" alt=""
                                                                class="img-fluid" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="content-task-ctl">
                                    <h2 class="border-bottom mb-3">{{ translations?.others_tasks }} </h2>
                                    <div class="table-responsive">
                                        <table class="table table-hover table-striped">
                                            <tbody>
                                                <tr>
                                                    <td><a href="">Send welcome email</a></td>
                                                    <td>15 Oct</td>
                                                    <td>
                                                        <span class="btn btn-danger rounded-pill">High</span>
                                                    </td>
                                                    <td>
                                                        <div class="profile-avatar">
                                                            <img :src="require('@/assets/img/john.jpg')" alt=""
                                                                class="img-fluid" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><a href="">Create boarding project</a></td>
                                                    <td>Today</td>
                                                    <td>
                                                        <span class="btn btn-warning rounded-pill">Medium</span>
                                                    </td>
                                                    <td>
                                                        <div class="profile-avatar">
                                                            <img :src="require('@/assets/img/john.jpg')" alt=""
                                                                class="img-fluid" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <h2 class="border-bottom mb-3">{{ translations?.wall }}</h2>
                                <div class="border rounded p-2 mb-3">
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <div class="d-flex">
                                                <div class="pe-2">
                                                    <a href="#">
                                                        <div class="d-flex">
                                                            <div class="pr-1">
                                                                <div class="post-user-profile">
                                                                    <img :src="require('@/assets/img/john.jpg')" alt=""
                                                                        class="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div>
                                                    <div class="align-content-start fw-bold mb-1">
                                                        Post title
                                                    </div>
                                                    <p>
                                                        Post content Post content Post content Post content
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="d-flex">
                                                <div class="pe-2">
                                                    <a href="#">
                                                        <div class="d-flex">
                                                            <div class="pe-3">
                                                                <div class="commenter-user-profile">
                                                                    <img :src="require('@/assets/img/john.jpg')" alt=""
                                                                        class="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div>
                                                    <p>This is commented text. This is commented text.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="d-flex">
                                                <div class="pe-2">
                                                    <a href="#">
                                                        <div class="d-flex">
                                                            <div class="pe-3">
                                                                <div class="commenter-user-profile">
                                                                    <img :src="require('@/assets/img/john.jpg')" alt=""
                                                                        class="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div class="w-100">
                                                    <input type="text" class="form-control" placeholder="comment ..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="border rounded p-2 mb-3">
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <div class="d-flex">
                                                <div class="pe-2">
                                                    <a href="#">
                                                        <div class="d-flex">
                                                            <div class="pr-1">
                                                                <div class="post-user-profile">
                                                                    <img :src="require('@/assets/img/john.jpg')" alt=""
                                                                        class="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div>
                                                    <div class="align-content-start fw-bold mb-1">
                                                        Post title
                                                    </div>
                                                    <p>
                                                        Post content Post content Post content Post content
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="d-flex">
                                                <div class="pe-2">
                                                    <a href="#">
                                                        <div class="d-flex">
                                                            <div class="pe-3">
                                                                <div class="commenter-user-profile">
                                                                    <img :src="require('@/assets/img/john.jpg')" alt=""
                                                                        class="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div>
                                                    <p>This is commented text. This is commented text.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="d-flex">
                                                <div class="pe-2">
                                                    <a href="#">
                                                        <div class="d-flex">
                                                            <div class="pe-3">
                                                                <div class="commenter-user-profile">
                                                                    <img :src="require('@/assets/img/john.jpg')" alt=""
                                                                        class="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div class="w-100">
                                                    <input type="text" class="form-control" placeholder="comment ..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <h2 class="border-bottom mb-3">{{ translations?.risk_and_status }}</h2>
                                <div class="row">
                                    <div class="col col-6">
                                        <p class="mb-2">
                                            <span class="fw-bold">{{ translations?.milestone }}:</span> 15 July 2021
                                        </p>
                                        <p class="mb-2">
                                            <span class="fw-bold">{{ translations?.application }}:</span> 700 info, 9
                                            documents
                                        </p>
                                    </div>
                                    <div class="col col-6">
                                        <div class="pie-block mb-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" fill="none" stroke="none"
                                                stroke-width="0" class="dxc dxc-chart" style="
                          line-height: normal;
                          user-select: none;
                          display: block;
                          overflow: hidden;
                        " transform="translate(-0.67,0)" width="180" height="150" direction="ltr">
                                                <defs>
                                                    <filter id="DevExpress_1" x="-50%" y="-50%" width="200%" height="200%"
                                                        transform="translate(0,0)">
                                                        <feGaussianBlur in="SourceGraphic" result="gaussianBlurResult"
                                                            stdDeviation="3"></feGaussianBlur>
                                                        <feOffset in="gaussianBlurResult" result="offsetResult" dx="2"
                                                            dy="6"></feOffset>
                                                        <feFlood result="floodResult" flood-color="" flood-opacity="0.8">
                                                        </feFlood>
                                                        <feComposite in="floodResult" in2="offsetResult" operator="in"
                                                            result="compositeResult"></feComposite>
                                                        <feComposite in="SourceGraphic" in2="compositeResult"
                                                            operator="over"></feComposite>
                                                    </filter>
                                                    <clipPath id="DevExpress_2">
                                                        <rect x="0" y="0" width="180" height="150"
                                                            transform="translate(0,0)"></rect>
                                                    </clipPath>
                                                </defs>
                                                <rect x="0" y="0" width="180" height="150" transform="translate(0,0)"
                                                    fill="gray" opacity="0.0001"></rect>
                                                <g class="dxc-background"></g>
                                                <g class="dxc-series-group">
                                                    <g class="dxc-series">
                                                        <g class="dxc-markers">
                                                            <path
                                                                d="M 39.20388 19.82071 A 75.00000 75.00000 0 1 0 165.00000 75.00000 L 90.00000 75.00000 A 0.00000 0.00000 0 1 1 90.00000 75.00000 Z"
                                                                transform="translate(0,0)" stroke-linejoin="round"
                                                                fill="#70c92f" stroke="#ffffff" stroke-width="0"></path>
                                                            <path
                                                                d="M 165.00000 75.00000 A 75.00000 75.00000 0 0 0 39.20388 19.82071 L 90.00000 75.00000 A 0.00000 0.00000 0 0 1 90.00000 75.00000 Z"
                                                                transform="translate(0,0)" stroke-linejoin="round"
                                                                fill="#f8ca00" stroke="#ffffff" stroke-width="0"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g class="dxc-labels-group">
                                                    <g class="dxc-labels" opacity="1" transform="translate(0,0)"></g>
                                                </g>
                                                <g class="dxc-legend" clip-path="url(#DevExpress_2)"
                                                    transform="translate(0,0)"></g>
                                                <g class="dxc-annotations"></g>
                                            </svg>
                                        </div>
                                        <p class="mb-2">7/12 {{ translations?.item_completed }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-6">
                                        <p class="mb-2">
                                            <span class="fw-bold"> {{ translations?.milestone }}:</span> 15 July 2021
                                        </p>
                                        <p class="mb-2">
                                            <span class="fw-bold"> {{ translations?.application }}:</span> 700 info, 9
                                            documents
                                        </p>
                                    </div>
                                    <div class="col col-6">
                                        <div class="pie-block mb-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" fill="none" stroke="none"
                                                stroke-width="0" class="dxc dxc-chart" style="
                          line-height: normal;
                          user-select: none;
                          display: block;
                          overflow: hidden;
                        " transform="translate(-0.67,0)" width="180" height="150" direction="ltr">
                                                <defs>
                                                    <filter id="DevExpress_1" x="-50%" y="-50%" width="200%" height="200%"
                                                        transform="translate(0,0)">
                                                        <feGaussianBlur in="SourceGraphic" result="gaussianBlurResult"
                                                            stdDeviation="3"></feGaussianBlur>
                                                        <feOffset in="gaussianBlurResult" result="offsetResult" dx="2"
                                                            dy="6"></feOffset>
                                                        <feFlood result="floodResult" flood-color="" flood-opacity="0.8">
                                                        </feFlood>
                                                        <feComposite in="floodResult" in2="offsetResult" operator="in"
                                                            result="compositeResult"></feComposite>
                                                        <feComposite in="SourceGraphic" in2="compositeResult"
                                                            operator="over"></feComposite>
                                                    </filter>
                                                    <clipPath id="DevExpress_2">
                                                        <rect x="0" y="0" width="180" height="150"
                                                            transform="translate(0,0)"></rect>
                                                    </clipPath>
                                                </defs>
                                                <rect x="0" y="0" width="180" height="150" transform="translate(0,0)"
                                                    fill="gray" opacity="0.0001"></rect>
                                                <g class="dxc-background"></g>
                                                <g class="dxc-series-group">
                                                    <g class="dxc-series">
                                                        <g class="dxc-markers">
                                                            <path
                                                                d="M 39.20388 19.82071 A 75.00000 75.00000 0 1 0 165.00000 75.00000 L 90.00000 75.00000 A 0.00000 0.00000 0 1 1 90.00000 75.00000 Z"
                                                                transform="translate(0,0)" stroke-linejoin="round"
                                                                fill="#70c92f" stroke="#ffffff" stroke-width="0"></path>
                                                            <path
                                                                d="M 165.00000 75.00000 A 75.00000 75.00000 0 0 0 39.20388 19.82071 L 90.00000 75.00000 A 0.00000 0.00000 0 0 1 90.00000 75.00000 Z"
                                                                transform="translate(0,0)" stroke-linejoin="round"
                                                                fill="#f8ca00" stroke="#ffffff" stroke-width="0"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                                <g class="dxc-labels-group">
                                                    <g class="dxc-labels" opacity="1" transform="translate(0,0)"></g>
                                                </g>
                                                <g class="dxc-legend" clip-path="url(#DevExpress_2)"
                                                    transform="translate(0,0)"></g>
                                                <g class="dxc-annotations"></g>
                                            </svg>
                                        </div>
                                        <p class="mb-2">7/12  {{ translations?.item_completed }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import { useStore } from "vuex";
import HelperFunction from "@/common/helpers";
import { computed, inject, onMounted } from "vue";
import { useRouter,useRoute } from "vue-router";

export default {
    name: "ProjectDashboard",
    setup() {
        const { data, storeFormData } = HelperFunction();
        const store = useStore();
        const route = useRoute()
        const router = useRouter();
        const swal = inject('$swal')
        const getProjectData = computed(() => store.getters.getProjectData);
        const invitationData = computed(
            () => store.getters.getProjectInvitationData
        );



        async function changeInvitationStatus(invitationId, projectId, status) {
            const data = {
                invitation_id: invitationId,
                project_id: projectId,
                status: status,
            };
            await storeFormData(
                { url: vueConfig.Dashboard.DashboardInvitationEndPoint },
                data
            ).then((response) => {
                // console.error(response.data.data)
                if (response.data.data === "Declined") {
                    const company = store.getters.getSelectedBoard;
                    const company_id = {
                        company_id: company.id ? company.id : null,
                    };
                    store.dispatch("getDashboardData", company_id);
                    router.push({ name: "dashboard" });
                } else {
                    router.go();
                }
            });
        }
        const notifications = computed(() => store.getters.allNotifications);
        const company = store.getters.getSelectedBoard;
        console.log(invitationData, "invitationData", invitationData.value.project_id);
        const notification_payload = {
            company_id: company.id ? company.id : null,
            project_id: route.params.id
        }
        onMounted(() => {
            store.dispatch('fetchNotifications', notification_payload); 
        })

        const formatDate = (date) => {
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return new Date(date).toLocaleDateString(undefined, options);
        };

        const translations = computed(() => {
            return store.state.translations;  
        });

        const completeProject = async (row) => {
            await swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, close it!'
            }).then(async (result) => {
                if (result.isConfirmed === true) {
                    let input = {
                        'status': 'Completed'
                    };
                    if (row.id) {
                        input._method = "PUT";
                    }
                    await storeFormData(
                        {url: `${vueConfig.Project.ChangeStatusEndPoint}/${row.id}`},
                        input,
                        false,
                    );
                }
            })
        }

        return {
            data,
            getProjectData,
            invitationData,
            changeInvitationStatus,
            completeProject,
            translations,
            notifications,
            formatDate
        };
    },
};
</script>

<style scoped>
.card-body svg {
    font-size: 25px;
}
</style>
